import { createStore } from 'vuex'

export default createStore({
  state: {
    settings:{
      "pexeso1":{
        "name":"Základy účetnictví",
        "countOfCards":30,
        "deckName":"cardDeck1",
        "rubCard":"rubSide",
        "pairs":{
          "card_1":"card_7",
          "card_2":"card_15",
          "card_3":"card_14",
          "card_4":"card_29",
          "card_5":"card_22",
          "card_6":"card_13",
          "card_7":"card_1",
          "card_8":"card_16",
          "card_9":"card_25",
          "card_10":"card_30",
          "card_11":"card_27",
          "card_12":"card_19",
          "card_13":"card_6",
          "card_14":"card_3",
          "card_15":"card_2",
          "card_16":"card_8",
          "card_17":"card_28",
          "card_18":"card_20",
          "card_19":"card_12",
          "card_20":"card_18",
          "card_21":"card_24",
          "card_22":"card_5",
          "card_23":"card_26",
          "card_24":"card_21",
          "card_25":"card_9",
          "card_26":"card_23",
          "card_27":"card_11",
          "card_28":"card_17",
          "card_29":"card_4",
          "card_30":"card_10",
        }
      },
      "pexeso2":{
        "name":"Finanční účetnictví",
        "countOfCards":30,
        "deckName":"cardDeck2",
        "rubCard":"rubSide",
        "pairs":{
          "card_1":"card_21",
          "card_2":"card_13",
          "card_3":"card_11",
          "card_4":"card_28",
          "card_5":"card_16",
          "card_6":"card_15",
          "card_7":"card_22",
          "card_8":"card_23",
          "card_9":"card_24",
          "card_10":"card_29",
          "card_11":"card_3",
          "card_12":"card_19",
          "card_13":"card_2",
          "card_14":"card_27",
          "card_15":"card_6",
          "card_16":"card_5",
          "card_17":"card_26",
          "card_18":"card_25",
          "card_19":"card_12",
          "card_20":"card_30",
          "card_21":"card_1",
          "card_22":"card_7",
          "card_23":"card_8",
          "card_24":"card_9",
          "card_25":"card_18",
          "card_26":"card_17",
          "card_27":"card_14",
          "card_28":"card_4",
          "card_29":"card_10",
          "card_30":"card_20",
        }
      }
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
