<template>
  <div class="container">
    <div class="row">
      <div class="col-12">

        <suspense>
          <component v-bind:is="state" v-bind:game="game" @start="onStart" v-bind:countOfPlayers="countOfPlayers"></component>
          <template #fallback>
            <div>
              <div class="splash">
                <div class="m-4">
                  <img class="w-100" src="./assets/logo-stohl.png" alt="Vzdělávací středisko Ing. Pavla Štohla" />
                </div>
                <div class="spinner-border text-warning" role="status"></div>
                <p class="text-light">Nahrávám data ...</p>
              </div>
            </div>
          </template>
        </suspense>

      </div>
    </div>

  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: 'App',
  components: {
    Choices: defineAsyncComponent(() => import('./components/Choices.vue')),
    Game: defineAsyncComponent(() => import('./components/Game.vue'))
  },
  props: ['game'],
  data() {
    return {
      state:"Choices",
      countOfPlayers:2
    };
  },
  beforeMount() {},
  mounted() {},
  beforeUnmount() {},
  computed: {},
  methods: {
    onStart( countOfPlayers ){
      this.countOfPlayers = Number(countOfPlayers)
      this.state = "Game"
    }
  }
}
</script>

<style lang="less">
html {
  scroll-behavior: smooth;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: #f0f0f0;
}

h1{
  font-family: 'Pacifico', cursive;
  color:green;
}

.splash{
  width:30%;
  background-color:#333;
  background-color:rgba(25, 25, 25, 0.8);
  background-repeat:no-repeat;
  text-align:center;
  margin:0 auto;
  padding:1em;
  border-radius:5px;
  box-shadow:2px 2px 3px #333;
  border-top:6px solid #ffc107;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
